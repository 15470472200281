import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
// import _ from "lodash";
import Phaser from "phaser";

export default class AfterQuestionScene extends Scene {
  constructor() {
    super({ key: "AfterQuestionScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.skyPosition = data.skyPosition ? data.skyPosition : 0;
  }

  playerAvatarObject = [];
  isFishingDone = false;
  fishScore = 0;
  fishWeight = 0;
  fishSize = 0.3;

  create() {
    this.fishes = [
      { img: "imgBox", name: i18n.t("miniGames.fishing.fish_1") },
      { img: "imgShrimp", name: i18n.t("miniGames.fishing.fish_2") },
      { img: "imgClam", name: i18n.t("miniGames.fishing.fish_3") },
      { img: "imgBlue", name: i18n.t("miniGames.fishing.fish_4") },
    ];
    this.selectedFish =
      this.fishes[Math.floor(Math.random() * this.fishes.length)];

    this.isFriendRound = false;

    this.initilizeTutorial();
    this.registerAnims();
    this.registerSounds();

    //* setup the Background  *//
    this.imgSky = this.add
      .tileSprite(0, 0, 480, 854, "imgBackground")
      .setOrigin(0);
    this.imgBG = this.add.image(240, 400, "imgBG").setScale(0.5);
    this.imgSky.tilePositionX = this.skyPosition;

    this.imgFarShip = this.add
      .image(400, 498, "imgFarShip")
      .setOrigin(0)
      .setScale(0.5);
    this.imgWave1 = this.add
      .image(-50, 330, "imgWave1")
      .setOrigin(0)
      .setScale(0.4);
    this.imgWave2 = this.add
      .image(-40, 340, "imgWave2")
      .setOrigin(0)
      .setScale(0.4);
    this.imgRodWire = this.add
      .image(297, 501, "imgRodWire")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);
    this.imgRod = this.add
      .image(150, 500, "imgRod")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);

    this.sptSplash = this.add
      .sprite(300, 620, "sptSplash")
      .setScale(0.7)
      .setDepth(7)
      .play("animSplash");

    this.imgFishingBarBack = this.add
      .image(240, 280, "imgFishingBarBack")
      .setOrigin(0.5, 0)
      .setScale(0.42, 0.5)
      .setDepth(8);

    const fishPosition = this.isFriendRound
      ? Math.floor(Math.random() * (300 - 120 + 1)) + 120
      : Math.floor(Math.random() * (300 - 80 + 1)) + 80;

    this.fishingBarRect = this.add
      .rectangle(
        fishPosition,
        310,
        this.isCorrectAnswer ? 50 : 100,
        50,
        0x2caaf6
      )
      .setDepth(8);

    this.imgFishingBarOutline = this.add
      .image(240, 280, "imgFishingBarOutline")
      .setOrigin(0.5, 0)
      .setScale(0.42, 0.5)
      .setDepth(9);

    this.imgFishingBarFish = this.add
      .image(fishPosition, 310, "imgFishingBarFish")
      .setOrigin(0.5)
      .setScale(0.4)
      .setDepth(9);

    this.imgFishingBarHook = this.add
      .image(40, 270, "imgFishingBarHook")
      .setOrigin(0.5, 0)
      .setScale(0.42)
      .setDepth(9);

    this.imgBoat = this.add
      .image(50, 600, "imgBoat")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(8);

    this.imgWave3 = this.add
      .image(-20, 360, "imgWave3")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(8);

    this.imgWave4 = this.add
      .image(-50, 340, "imgWave4")
      .setOrigin(0)
      .setScale(0.5)
      .setDepth(8);

    this.imgFishingBtn = this.add
      .image(240, 730, "imgFishingBtn")
      .setOrigin(0.5)
      .setDepth(8);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreBoard = this.add.image(100, 40, "imgScorePanel").setScale(0.4);
    this.roundBoard = this.add.image(73, 85, "imgScorePanel").setScale(0.25);

    this.scoreText = this.add
      .text(
        40,
        40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5);

    this.roundText = this.add
      .text(35, 87, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);

    this.dark = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerNameBoard = this.add.rectangle(120, 480, 180, 40, 0xffffff, 0.5);

    this.playerName = this.add
      .text(
        120,
        480,
        this.name.length > 20 ? this.name.substring(0, 12) : this.name,
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
          align: "center",
        }
      )
      .setOrigin(0.5);

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 4000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.btnTween = this.tweens.add({
      targets: this.imgFishingBtn,
      scaleX: 0.95,
      scaleY: 0.95,
      duration: 500,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween1 = this.tweens.add({
      targets: [this.imgWave1, this.imgFarShip],
      y: "-=5",
      duration: 3000,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween2 = this.tweens.add({
      targets: [this.imgWave2],
      y: "-=10",
      duration: 3500,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween3 = this.tweens.add({
      targets: [
        this.imgWave3,
        this.imgBoat,
        this.imgRod,
        // this.imgRodHook,
        this.imgRodWire,
        this.sptSplash,
        // this.playerName,
        // this.playerNameBoard,
      ],
      y: "-=10",
      duration: 4000,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween4 = this.tweens.add({
      targets: [this.imgWave4],
      y: "-=20",
      duration: 4500,
      repeat: -1,
      yoyo: true,
    });

    this.time.delayedCall(1000, () => {
      this.magicAnimm = this.add
        .sprite(this.fishingBarRect.x, this.fishingBarRect.y, "sptMagic")
        .setDepth(9)
        .setScale(this.isCorrectAnswer ? 1.1 : 0.9)
        .play(this.isCorrectAnswer ? "animMagicC" : "animMagicW");

      if (this.isCorrectAnswer) {
        this.sfxMagic.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxMagic.play();
      } else {
        this.sfxMagicW.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxMagicW.play();
        this.magicAnimm.setTint(0xff0000);
      }

      let Temx = this.fishingBarRect.x;
      this.fishingBarRect.destroy();
      this.fishingBarRect = this.add
        .rectangle(Temx, 310, this.isCorrectAnswer ? 100 : 50, 50, 0x2caaf6)
        .setDepth(8);
      // if (this.isFriendRound) {
      //   this.friendEnter();
      // }
      this.time.delayedCall(1000, () => {
        if (this.round == 1) {
          this.currentChatId = 1;
          this.currentChatIndex = 0;
          if (this.isCorrectAnswer) {
            this.tutorialChat.chat = this.add
              .image(90, 755, "imgHamochiHappy")
              .setScale(0.25)
              .setDepth(10)
              .setAlpha(0);
            this.chats = this.chats_1_a;
          } else {
            this.tutorialChat.chat = this.add
              .image(90, 755, "imgHamochiSad")
              .setScale(0.25)
              .setDepth(10)
              .setAlpha(0);
            this.chats = this.chats_1_b;
          }
          this.chats = this.isCorrectAnswer ? this.chats_1_a : this.chats_1_b;
          this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);

          this.startTutorial();
        } else {
          this.startGame();
        }
      });
    });
  }

  update() {
    this.imgSky.tilePositionX += 0.2;
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }

    if (this.friendMask && this.friendAvatar[0]) {
      this.friendMask.x = this.friendAvatar[0].x;
      this.friendMask.y = this.friendAvatar[0].y;
    }

    if (this.friendBoat) {
      this.friendBoat.x = this.friendAvatar[0].x - 70;
    }
  }

  initilizeTutorial() {
    this.chats_1_a = [i18n.t("miniGames.fishing.tutorial.dialog_12_a")];
    this.chats_1_b = [i18n.t("miniGames.fishing.tutorial.dialog_12_b")];
    this.chats_2 = [i18n.t("miniGames.fishing.tutorial.dialog_7")];
    this.chats_3_a = [i18n.t("miniGames.fishing.tutorial.dialog_8_a")];
    this.chats_3_b = [i18n.t("miniGames.fishing.tutorial.dialog_8_b")];
    this.chats_4 = [i18n.t("miniGames.fishing.tutorial.dialog_9")];
    this.chats_5 = [
      i18n.t("miniGames.fishing.tutorial.dialog_10"),
      i18n.t("miniGames.fishing.tutorial.dialog_11"),
    ];

    this.tutorialBackground = this.add.rectangle(240, 427, 480, 854, 0x000000);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      if (!this.onShake) {
        this.onShake = true;
        this.tweens.add({
          targets: this.tutorialChat,
          x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.onShake = false;
          },
        });
        this.tweens.add({
          targets: this.tutorialChat.chat,
          x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.text,
          x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.button,
          x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.buttonText,
          x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
      }
    });
    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 680, 460, 154, 20);

    this.anims.create({
      key: "animClick",
      frames: this.anims.generateFrameNumbers("sptClick", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add
      .sprite(0, 0, "sptClick")
      .setScale(0)
      .setDepth(11)
      .play("animClick");

    this.tutorialActionBackground = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0)
      .setDepth(10);

    this.tutorialArrow = this.add
      .image(0, 0, "imgArrow")
      .setScale(0)
      .setDepth(10);

    this.tutorialChatRect = new Phaser.Geom.Rectangle(10, 680, 460, 154);

    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add
      .image(90, 755, "imgHamochiTalk")
      .setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 740, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(334, 802, 124, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      340,
      805,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialBackground.setDepth(10);
    this.tutorialChat.setDepth(10);
    this.tutorialChat.chat.setDepth(10);
    this.tutorialChat.text.setDepth(10);
    this.tutorialChat.button.setDepth(10);
    this.tutorialChat.buttonText.setDepth(10);

    this.tutorialBackground.setAlpha(0);
    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);
  }

  startTutorial() {
    this.tutorialChat.buttonText.removeInteractive();
    this.tutorialChat.removeInteractive();
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.7,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                  onComplete: () => {
                    this.tutorialChat.buttonText.setInteractive({
                      useHandCursor: true,
                    });
                    this.tutorialChat.setInteractive(
                      this.tutorialChatRect,
                      Phaser.Geom.Rectangle.Contains
                    );
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  continueChat() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.currentChatIndex++;
    this.tutorialChat.buttonText.removeInteractive();
    this.tutorialChat.removeInteractive();
    if (this.chats[this.currentChatIndex]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
            onComplete: () => {
              this.tutorialChat.buttonText.setInteractive();
              this.tutorialChat.setInteractive(
                this.tutorialChatRect,
                Phaser.Geom.Rectangle.Contains
              );
            },
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.buttonText.setInteractive();
          this.tutorialChat.setInteractive(
            this.tutorialChatRect,
            Phaser.Geom.Rectangle.Contains
          );
        },
      });

      if (this.currentChatId == 1) {
        this.currentChatId = -1;
        this.startGame();
        this.time.delayedCall(1000, () => {
          this.currentChatId = 2;
          this.currentChatIndex = 0;
          this.chats = this.chats_2;
          this.tutorialChat.chat = this.add
            .image(90, 755, "imgHamochiTalk")
            .setScale(0.25)
            .setDepth(10)
            .setAlpha(0);
          this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
          this.startTutorial();
        });
      }
      if (this.currentChatId == 2) {
        this.currentChatId = -2;
        this.tutorialActionBackground.setAlpha(0.6);
        this.imgFishingBarBack.setDepth(11);
        this.fishingBarRect.setDepth(11);
        this.imgFishingBarOutline.setDepth(12);
        this.imgFishingBarFish.setDepth(12);
        this.imgFishingBarHook.setDepth(12);
        this.imgFishingBtn.setDepth(11);

        this.tutorialClick.x = this.imgFishingBtn.x + 50;
        this.tutorialClick.y = this.imgFishingBtn.y + 20;
        this.time.delayedCall(500, () => {
          this.imgFishingBtn
            .setInteractive({ useHandCursor: true })
            .on("pointerdown", this.fishing, this);
          this.tutorialClick.setScale(0.4);
        });
      }
      if (this.currentChatId == 3) {
        this.currentChatId = -3;
        this.showScore();
      }
      if (this.currentChatId == 4) {
        this.currentChatId = -4;
        this.goNext();
      }
      if (this.currentChatId == 5) {
        this.currentChatId = -5;
        router.push({
          name: "PageEventGameTutorialCompleted",
        });
      }
    }
  }

  friendEnter() {
    this.friendAvatar = [];

    for (let key of Object.keys(this.friend.avatar)) {
      if (this.friend.avatar[key]) {
        if (key == "body") {
          this.friendAvatar.push(
            this.add
              .image(580, 560, key)
              .setScale(0.25)
              .setDepth(2)
              .setFlipX(true)
          );
          this.friendAvatar.push(
            this.add
              .image(580, 560, key)
              .setTint("0x" + this.friend.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setDepth(2)
              .setFlipX(true)
          );
        } else if (key != "color" && key != "right") {
          this.friendAvatar.push(
            this.add
              .image(580, 560, key)
              .setScale(0.25)
              .setDepth(2)
              .setFlipX(true)
          );
        }
      }
    }

    this.friendBoat = this.add
      .image(580, 600, "imgBoat")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(3);

    this.itemTween3 = this.tweens.add({
      targets: [this.friendBoat],
      y: "-=10",
      duration: 4000,
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: this.friendAvatar,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 4000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: this.friendAvatar,
      x: "400",
      duration: 800,
      ease: "Linear",
      onComplete: () => {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskHappyx"
          )
          .setScale(0.25)
          .setDepth(6);

        this.friendNameBoard = this.add.rectangle(
          400,
          480,
          180,
          40,
          0xffffff,
          0.5
        );

        this.friendName = this.add
          .text(
            400,
            480,
            this.friend.name.length > 20
              ? this.friend.name.substring(0, 12)
              : this.friend.name,
            {
              color: "#000",
              fontSize: "15px",
              fontFamily: "Sassoon, sans-serif",
              align: "center",
            }
          )
          .setOrigin(0.5);

        if (this.friendMask) {
          this.friendMask.destroy();
        }
        this.friendMask = this.add
          .image(this.friendAvatar[0].x, this.friendAvatar[0].y, "maskHappyx")
          .setScale(0.25)
          .setDepth(2)
          .setFlipX(true);

        this.time.delayedCall(800, () => {
          if (!this.isFishingDone) {
            this.imgFriendBtn = this.add
              .image(400, 560, "imgFriendBtn")
              .setScale(0.2)
              .setDepth(9);
            this.clickFriendBtn();
          }
        });

        // .setInteractive({ useHandCursor: true })
        // .on("pointerdown", this.clickFriendBtn, this);

        // this.tweens.add({
        //   targets: this.imgFriendBtn,
        //   y: "-=150",
        //   scaleX: 0.4,
        //   scaleY: 0.4,
        //   duration: 1000,
        //   ease: "Sine.easeInOut",
        //   onComplete: () => {
        //     this.btnTween = this.tweens.add({
        //       targets: this.imgFriendBtn,
        //       scaleX: "+=0.05",
        //       scaley: "+=0.05",
        //       duration: 500,
        //       repeat: -1,
        //       yoyo: true,
        //     });
        //   },
        // });
      },
    });
  }

  clickFriendBtn() {
    this.tweens.add({
      targets: this.imgFriendBtn,
      y: this.imgFishingBarFish.y,
      x: this.imgFishingBarFish.x,
      scaleX: 0.1,
      scaleY: 0.1,
      duration: 500,
      ease: "Sine.easeInOut",
      onComplete: () => {
        this.sfxFriend.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxFriend.play();
        this.imgFriendBtn.destroy();
        let Temx = this.fishingBarRect.x;
        this.fishingBarRect.destroy();

        this.fishingBarRect = this.add
          .rectangle(Temx, 310, 160, 50, 0x2caaf6)
          .setDepth(8);
        this.startGame();
      },
    });
  }

  showScore() {
    this.scoreDetails = [];
    this.scoreDetails.push(
      this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.6).setDepth(9)
    );

    this.scoreDetails.push(
      this.add.image(240, 420, "imgSettingPanel").setScale(1, 1.1).setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .image(
          240,
          375,
          this.fishScore > 0 ? this.selectedFish.img : "imgTrash"
        )
        .setScale(this.fishSize + 0.2)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(
          240,
          445,
          this.fishScore > 0
            ? this.selectedFish.name
            : i18n.t("miniGames.fishing.trash"),
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 20,
            color: "#492a12",
            align: "center",
          }
        )
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(
          240,
          480,
          this.fishScore > 0 ? this.fishWeight + " Kg" : "0.50 Kg",
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 22,
            color: "#492a12",
            align: "center",
          }
        )
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .text(
          240,
          290,
          i18n.t("miniGames.general.score") + " : + " + this.fishScore,
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 32,
            color: "#492a12",
            align: "center",
          }
        )
        .setOrigin(0.5)
        .setDepth(9)
    );

    this.scoreDetails.push(
      this.add
        .image(240, 540, "imgOptionBtn")
        .setScale(0.25, 0.3)
        .setDepth(9)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.clickNext, this)
    );

    if (this.round == 1) {
      this.tutorialClick.x = 290;
      this.tutorialClick.y = 560;
      this.time.delayedCall(500, () => {
        this.tutorialClick.setScale(0.4);
      });
    }

    this.scoreDetails.push(
      this.add
        .text(240, 540, i18n.t("miniGames.general.ok"), {
          fontSize: "24px",
          fill: "#000",
          fontFamily: "Sassoon, sans-serif",
        })
        .setDepth(9)
        .setOrigin(0.5)
    );
  }

  clickNext() {
    this.tutorialClick.setScale(0);

    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scoreDetails.forEach((element) => {
      element.destroy();
    });
    this.time.delayedCall(1000, () => {
      // this.imgCatch.destroy();
      this.scoreIndication(300, 500, this.fishScore);
      if (this.isFriendRound) {
        this.time.delayedCall(500, () => {
          this.friendNameBoard.setAlpha(0);
          this.friendName.setAlpha(0);
          this.tweens.add({
            targets: this.friendAvatar,
            x: 580,
            delay: 500,
            duration: 1000,
            ease: "Sine.easeInOut",
            onComplete: () => {
              this.friendAvatar.forEach((element) => {
                element.destroy();
              });
              this.friendNameBoard.destroy();
              this.friendName.destroy();
            },
          });
        });
      }
      if (this.round == 1) {
        this.currentChatId = 4;
        this.currentChatIndex = 0;
        this.chats = this.chats_4;
        this.tutorialChat.chat = this.add
          .image(90, 755, "imgHamochiTalk")
          .setScale(0.25)
          .setDepth(10)
          .setAlpha(0);
        this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
        this.startTutorial();
      } else {
        this.currentChatId = 5;
        this.currentChatIndex = 0;
        this.chats = this.chats_5;
        this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
        this.startTutorial();
      }
    });
  }

  fishing() {
    if (!this.isFishingDone) {
      if (this.imgFriendBtn) {
        this.imgFriendBtn.destroy();
      }
      this.sfxStart.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxStart.play();
      this.btnTween.stop();
      this.imgFishingBtn.removeInteractive();
      this.tweens.add({
        targets: this.imgFishingBtn,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeInOut",
      });
      this.isFishingDone = true;
      if (this.currentChatId == -2) {
        this.tutorialActionBackground.setAlpha(0);
        this.tutorialClick.setScale(0);
        this.imgFishingBarBack.setDepth(8);
        this.fishingBarRect.setDepth(8);
        this.imgFishingBarOutline.setDepth(9);
        this.imgFishingBarFish.setDepth(9);
        this.imgFishingBarHook.setDepth(9);
        this.imgFishingBtn.setDepth(8);
      }
      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          this.fishingBarRect.getBounds(),
          new Phaser.Geom.Rectangle(
            this.imgFishingBarHook.getBounds().x + 20,
            this.imgFishingBarHook.getBounds().y,
            this.imgFishingBarHook.getBounds().width - 40,
            this.imgFishingBarHook.getBounds().height
          )
        )
      ) {
        var distance = Math.round(
          Math.abs(this.imgFishingBarHook.x - this.imgFishingBarFish.x)
        );

        if (distance < 5) {
          this.fishWeight = 1.0;
          this.fishScore = 20;
          this.fishSize = 0.35;
        } else if (distance < 10) {
          this.fishWeight = 0.9;
          this.fishScore = 18;
          this.fishSize = 0.34;
        } else if (distance < 15) {
          this.fishWeight = 0.8;
          this.fishScore = 16;
          this.fishSize = 0.33;
        } else if (distance < 20) {
          this.fishWeight = 0.7;
          this.fishScore = 14;
          this.fishSize = 0.32;
        } else if (distance < 25) {
          this.fishWeight = 0.6;
          this.fishScore = 12;
          this.fishSize = 0.31;
        } else if (distance < 30) {
          this.fishWeight = 0.35;
          this.fishScore = 11;
          this.fishSize = 0.3;
        } else if (distance < 35) {
          this.fishWeight = 0.5;
          this.fishScore = 10;
          this.fishSize = 0.29;
        } else if (distance < 40) {
          this.fishWeight = 0.45;
          this.fishScore = 9;
          this.fishSize = 0.28;
        } else {
          this.fishWeight = 0.4;
          this.fishScore = 8;
          this.fishSize = 0.27;
        }

        if (this.playerMask) {
          this.playerMask.destroy();
        }
        let playerEye = this.playerAvatarObject.find(
          (sprite) => sprite.texture.key === "eye"
        );
        if (playerEye) {
          playerEye.setAlpha(0);
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskHappy"
          )
          .setScale(0.25)
          .setDepth(6);

        this.imgCatch = this.add
          .image(300, 750, this.selectedFish.img)
          .setOrigin(0.5, 0)
          .setScale(this.fishSize)
          .setDepth(7);

        this.imgReaction = this.add
          .image(180, 520, "imgReactionLove")
          .setScale(0)
          .setDepth(6);

        this.sfxReaction = this.sfxFishSuccess;

        // this.feedbackBack = this.add
        //   .graphics()
        //   .fillStyle(0x00ff00)
        //   .fillRoundedRect(140, 370, 200, 60, 15)
        //   .setDepth(9);
        // this.feedbackTxt = this.add
        //   .text(240, 400, "Success", {
        //     fontSize: "24px",
        //     fill: "#000",
        //     fontFamily: "Sassoon, sans-serif",
        //   })
        //   .setDepth(9)
        //   .setOrigin(0.5);
        if (this.currentChatId == -2) {
          this.chats = this.chats_3_a;
          this.tutorialChat.chat = this.add
            .image(90, 755, "imgHamochiHappy")
            .setScale(0.25)
            .setDepth(10)
            .setAlpha(0);
        }
      } else {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        let playerEye = this.playerAvatarObject.find(
          (sprite) => sprite.texture.key === "eye"
        );
        if (playerEye) {
          playerEye.setAlpha(0);
        }
        this.playerMask = this.add
          .image(
            this.playerAvatarObject[0].x,
            this.playerAvatarObject[0].y,
            "maskSad"
          )
          .setScale(0.25)
          .setDepth(6);
        this.imgCatch = this.add
          .image(300, 750, "imgTrash")
          .setOrigin(0.5, 0)
          .setScale(0.3)
          .setDepth(7);

        this.imgReaction = this.add
          .image(180, 520, "imgReaction!")
          .setScale(0)
          .setDepth(6);

        this.sfxReaction = this.sfxFishFailed;

        // this.feedbackBack = this.add
        //   .graphics()
        //   .fillStyle(0xff0000)
        //   .fillRoundedRect(140, 370, 200, 60, 15)
        //   .setDepth(9);
        // this.feedbackTxt = this.add
        //   .text(240, 400, "Failed", {
        //     fontSize: "24px",
        //     fill: "#fff",
        //     fontFamily: "Sassoon, sans-serif",
        //   })
        //   .setDepth(9)
        //   .setOrigin(0.5);
        if (this.currentChatId == -2) {
          this.chats = this.chats_3_b;
          this.tutorialChat.chat = this.add
            .image(90, 755, "imgHamochiSad")
            .setScale(0.25)
            .setDepth(10)
            .setAlpha(0);
        }
      }
      this.sptSplash.setAlpha(0);

      this.hookTween.stop();
      this.sfxSplash.stop();
      this.tweens.add({
        targets: [this.imgRodWire],
        scaleY: 0.05,
        duration: 1000,
        onStart: () => {
          this.tweens.add({
            targets: [this.imgCatch],
            y: "-= 240",
            duration: 1000,
          });
        },
        onComplete: () => {
          this.tweens.add({
            targets: [this.imgReaction],
            scaleX: 0.4,
            scaleY: 0.4,
            duration: 100,
          });

          this.sfxReaction.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxReaction.play();

          this.time.delayedCall(1000, () => {
            // this.feedbackBack.destroy();
            // this.feedbackTxt.destroy();

            if (this.currentChatId == -2) {
              this.currentChatId = 3;
              this.currentChatIndex = 0;
              this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
              this.startTutorial();
            } else {
              this.showScore();
            }
          });
        },
      });
    }
  }

  startGame() {
    this.magicAnimm.destroy();
    if (this.currentChatId != -1) {
      this.imgFishingBtn
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.fishing, this);
    }
    this.hookTween = this.tweens.add({
      targets: this.imgFishingBarHook,
      x: "+=400",
      duration: this.difficulty == 1 ? 1400 : this.difficulty == 2 ? 1000 : 600,
      repeat: -1,
      yoyo: true,
      onStart: () => {
        this.sfxSplash.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxSplash.play();
      },
    });
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxFishSuccess = this.sound.add("sfxFishSuccess");
    this.sfxFishFailed = this.sound.add("sfxFishFailed");
    this.sfxSplash = this.sound.add("sfxSplash");
    this.sfxSplash.loop = true;
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxStart = this.sound.add("sfxStart");
    this.sfxFriend = this.sound.add("sfxFriend");
    this.sfxMagic = this.sound.add("sfxMagic");
    this.sfxMagicW = this.sound.add("sfxMagicW");

    // this.sfxReady = this.sound.add("sfxReady");
    // this.sfxGo = this.sound.add("sfxGo");
    // this.sfxRound = this.sound.add("sfxRound");
  }

  registerAnims() {
    this.anims.create({
      key: "animSplash",
      frames: this.anims.generateFrameNumbers("sptSplash", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "animMagicC",
      frames: this.anims.generateFrameNumbers("sptMagic", {
        frames: [1, 2],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "animMagicW",
      frames: this.anims.generateFrameNumbers("sptMagic", {
        frames: [5, 6],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "animMagicF",
      frames: this.anims.generateFrameNumbers("sptMagic", {
        frames: [3, 4],
      }),
      frameRate: 5,
      repeat: -1,
    });
  }

  goNext() {
    this.itemTween1.stop();
    this.itemTween2.stop();
    this.itemTween3.stop();
    this.itemTween4.stop();
    this.playerTween.stop();

    this.tweens.add({
      targets: [this.imgWave1],
      y: 330,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgWave2],
      y: 340,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgWave3],
      y: 360,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgWave4],
      y: 340,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgBoat],
      y: 600,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgRod],
      y: 500,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgRodWire],
      y: 501,
      duration: 2000,
    });

    this.tweens.add({
      targets: [this.imgFarShip],
      y: 498,
      duration: 2000,
    });

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.25,
      scaleX: 0.25,
      y: 560,
      duration: 2000,
      ease: "Sine.easeInOut",
    });

    this.time.delayedCall(2000, () => {
      this.playerAvatarObject = [];
      this.isFishingDone = false;
      this.fishScore = 0;
      this.fishWeight = 0;
      this.fishSize = 0.3;

      this.registry.destroy();
      this.events.off();
      this.scene.stop("AfterQuestionScene");

      this.scene.start("BeforeQuestionScene", {
        avatar: this.avatar,
        name: this.name,
        round: this.round + 1,
        score: this.score,
        questions: this.questions,
        friend: this.friend,
        difficulty: this.difficulty,
        countCorrect: this.countCorrect,
        noOfRounds: this.noOfRounds,
        skyPosition: this.imgSky.tilePositionX,
      });
    });
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "AfterQuestionScene",
    });
  }

  scoreIndication(x, y, score, increase = true) {
    let indication = this.add
      .text(x, y, increase ? "+ " + score : "- " + score, {
        color: increase ? "#000" : "#ff0000",
        fontSize: "20px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(0.5);
    this.tweens.add({
      targets: indication,
      alpha: 0,
      y: "-=50",
      duration: 1000,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.sfxScoreIncrease.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxScoreIncrease.play();
      },
      onComplete: () => {
        indication.destroy();
        this.score += score;
        this.scoreText.setText(
          i18n.t("miniGames.general.score") + " : " + this.score
        );
      },
    });
  }
}
