import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import Phaser from "phaser";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class QuestionScene extends Scene {
  constructor() {
    super({ key: "QuestionScene" });
  }

  avatarObject = [];
  isAnswered = false;
  questionScore = 80;
  canAnswer = false;
  chats = [];
  currentChatId = 0;

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.questions = data.questions;
    this.question = this.questions[this.round - 1];
    this.score = data.score;
    this.friend = data.friend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.playerHP = data.playerHP;
    this.enemyHP = data.enemyHP;
    this.enemyCount = data.enemyCount;
    this.isFriendCame = data.isFriendCame;
    this.noOfRounds = data.noOfRounds;
  }

  preload() {
    this.chats_1 = [i18n.t("miniGames.fishing.tutorial.dialog_5")];
    this.chats_2 = [i18n.t("miniGames.fishing.tutorial.dialog_6_a")];
    this.chats_3 = [i18n.t("miniGames.fishing.tutorial.dialog_6_b")];

    this.load.html("question", this.question.question);
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
  }

  create() {
    this.add.image(80, 35, "imgScorePanel").setScale(0.3);
    this.add.image(70, 70, "imgScorePanel").setScale(0.25, 0.2);
    this.settingBtn = this.add.image(440, 40, "imgSettingBtn").setScale(0.6);
    this.settingBtn.on("pointerdown", clickSetting, this);

    if (this.round != 1) {
      this.settingBtn.setInteractive({ useHandCursor: true });
    }

    this.scoreText = this.add
      .text(30, 35, i18n.t("miniGames.general.score") + " : " + this.score, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 18,
        color: "#000",
      })
      .setOrigin(0, 0.5);

    this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#000",
      })
      .setOrigin(0, 0.5);

    this.q_dom = this.add.dom(240, 110).createFromHTML(`
      <div style="
        width: 440px;
        height: 380px;
        border-radius: 20px;
        background-color: #fff;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      "></div>
      `);
    this.q_dom.setOrigin(0.5, 0);
    this.q_dom.setPosition(240, 110);
    this.q_dom.node.innerHTML = this.question.question;
    this.q_dom.node.style.borderRadius = "10px";
    this.q_dom.node.style.padding = "20px";
    this.q_dom.node.style.backgroundColor = "#ffffff";
    this.q_dom.node.style.height = "380px";
    this.q_dom.node.style.width = "440px";
    this.q_dom.node.style.overflowY = "auto";

    this.okBtn = this.add.image(360, 780, "imgOkBtn").setScale(0).setDepth(3);
    this.okBtnTxt = this.add
      .text(360, 780, i18n.t("miniGames.general.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 42,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(3);

    // Sounds
    this.sfxCorrectAnswer = this.sound.add("sfxCorrectAnswer");
    this.sfxWrongAnswer = this.sound.add("sfxWrongAnswer");
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");

    this.okBtn.on(
      "pointerdown",
      () => {
        this.sfxSimpleClick.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxSimpleClick.play();
        this.avatarObject = [];
        this.isAnswered = false;
        this.registry.destroy();
        this.events.off();
        this.scene.stop("QuestionScene");
        this.scene.start("AfterQuestionScene", {
          avatar: this.avatar,
          name: this.name,
          round: this.round,
          qCount: this.qCount + 1,
          score: this.score,
          questions: this.questions,
          friend: this.friend,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
          isCorrectAnswer: this.isCorrectAnswer,
          noOfRounds: this.noOfRounds,
          skyPosition: this.skyPosition,
        });
      },
      this
    );

    let y = -1;
    for (let i = 0; i < this.question.options.length; i++) {
      let x = 0;
      if (i % 2 == 0) {
        x = 20;
        y++;
      } else {
        x = 250;
      }
      let option = this.add
        .image(x, 560 + y * 80, "imgAnswerPanel")
        .setOrigin(0, 0.5)
        .setScale(1, 1.5);

      if (i18n.locale == "zh") {
        let word =
          String.fromCharCode(i + 65) + ") " + this.question.options[i].text;
        let maxWidth = 180;

        let text = this.add
          .text(x + 20, 560 + y * 80, "", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 18,
            color: "#000",
          })
          .setOrigin(0, 0.5);

        let wrappedWord = "";
        let accumulatedWidth = 0;

        for (let i = 0; i < word.length; i++) {
          const letter = word[i];
          text.text = letter;
          const letterWidth = text.getBounds().width;

          accumulatedWidth += letterWidth;

          if (accumulatedWidth > maxWidth) {
            wrappedWord += "\n";
            accumulatedWidth = letterWidth;
          }

          wrappedWord += letter;
        }

        text.setText(wrappedWord);
      } else {
        this.add
          .text(
            x + 20,
            560 + y * 80,
            String.fromCharCode(i + 65) + ") " + this.question.options[i].text,
            {
              fontFamily: "Sassoon, sans-serif",
              wordWrap: { width: 180 },
              fontSize: 18,
              color: "#000",
            }
          )
          .setOrigin(0, 0.5);
      }

      option.isAnswer = this.question.options[i].isAnswer;
      option.setInteractive({ useHandCursor: true });

      if (option.isAnswer) {
        this.answer = this.add
          .image(x + 15, 545 + y * 80, "imgAnswerTag")
          .setScale(0.45);
        this.answer.visible = false;
      }

      option.on("pointerdown", () => {
        if (!this.isAnswered && this.canAnswer) {
          this.sfxSimpleClick.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxSimpleClick.play();
          this.isAnswered = true;
          this.answer.setTint(option.isAnswer ? 0x00ff00 : 0xff0000);
          this.answer.visible = true;
          if (option.isAnswer) {
            this.score += this.questionScore;
            this.scoreText.setText(
              i18n.t("miniGames.general.score") + " : " + this.score
            );
            this.countCorrect += 1;
            this.add
              .image(option.x + 180, option.y, "imgCorrectMark")
              .setScale(0.5);
            this.okHamochi = this.add
              .image(360, 730, "imgCorrectAns")
              .setScale(0)
              .setDepth(2);
            this.isCorrectAnswer = true;
          } else {
            this.add
              .image(option.x + 180, option.y, "imgWrongMark")
              .setScale(0.5);
            this.okHamochi = this.add
              .image(360, 730, "imgWrongAns")
              .setScale(0)
              .setDepth(2);
            this.isCorrectAnswer = false;
          }
          this.tweens.add({
            targets: [this.okBtn, this.okBtnTxt, this.okHamochi],
            scaleX: 0.6,
            scaleY: 0.6,
            duration: 200,
            ease: "Sine.easeOut",
            delay: 500,
            onStart: () => {
              if (this.isCorrectAnswer) {
                this.sfxCorrectAnswer.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxCorrectAnswer.play();
              } else {
                this.sfxWrongAnswer.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxWrongAnswer.play();
              }
            },

            onComplete: () => {
              if (this.isCorrectAnswer) {
                this.speechText = this.add
                  .text(
                    360,
                    680,
                    i18n.t("miniGames.general.score") +
                      " +" +
                      this.questionScore,
                    {
                      fontFamily: "Sassoon, sans-serif",
                      fontSize: 22,
                      color: "#000",
                    }
                  )
                  .setOrigin(0.5);

                this.tweens.add({
                  targets: this.speechText,
                  y: 600,
                  alpha: 0,
                  duration: 3000,
                  ease: "Sine.easeOut",
                  onStart: () => {
                    this.sfxScoreIncrease.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.sfxScoreIncrease.play();
                  },
                });
              }

              // continue tutorial
              if (this.round == 1) {
                this.currentChatId = 0;
                if (this.isCorrectAnswer) {
                  this.chats = this.chats_2;
                  if (this.tutorialChat.chat) {
                    this.tutorialChat.chat.destroy();
                  }
                  this.tutorialChat.chat = this.add
                    .image(90, 755, "imgHamochiHappy")
                    .setScale(0.25)
                    .setDepth(9)
                    .setAlpha(0);
                } else {
                  this.chats = this.chats_3;
                  if (this.tutorialChat.chat) {
                    this.tutorialChat.chat.destroy();
                  }
                  this.tutorialChat.chat = this.add
                    .image(90, 755, "imgHamochiSad")
                    .setScale(0.25)
                    .setDepth(9)
                    .setAlpha(0);
                }
                this.showClick = true;
                this.tutorialChat.text.setText(this.chats[this.currentChatId]);
                this.startTutorial();
              }
            },
          });
        }
      });
    }

    // Tutorial
    this.tutorialBackground = this.add.rectangle(240, 427, 480, 854, 0x000000);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      if (!this.onShake) {
        this.onShake = true;
        this.tweens.add({
          targets: this.tutorialChat,
          x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.onShake = false;
          },
        });
        this.tweens.add({
          targets: this.tutorialChat.chat,
          x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.text,
          x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.button,
          x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.buttonText,
          x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
      }
    });
    this.anims.create({
      key: "click_anim",
      frames: this.anims.generateFrameNumbers("sptClick", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add.sprite(420, 800, "sptClick").setScale(0);
    this.tutorialClick.play("click_anim");
    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 680, 460, 154, 20);

    this.tutorialChatRect = new Phaser.Geom.Rectangle(10, 680, 460, 154);
    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add
      .image(90, 755, "imgHamochiTalk")
      .setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 740, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(334, 802, 124, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      340,
      805,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialBackground.setDepth(9);
    this.tutorialClick.setDepth(9);
    this.tutorialChat.setDepth(9);
    this.tutorialChat.chat.setDepth(9);
    this.tutorialChat.text.setDepth(9);
    this.tutorialChat.button.setDepth(9);
    this.tutorialChat.buttonText.setDepth(9);

    this.tutorialBackground.setAlpha(0);
    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);

    // start tutorial
    if (this.round == 1) {
      this.currentChatId = 0;
      this.chats = this.chats_1;
      this.tutorialChat.text.setText(this.chats[this.currentChatId]);
      this.startTutorial();
    } else {
      this.okBtn.setInteractive({ cursor: "pointer" });
      this.canAnswer = true;
    }

    function clickSetting() {
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "QuestionScene",
      });
      this.sfxSimpleClick.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxSimpleClick.play();
    }

    this.events.on("pause", () => {
      this.q_dom.visible = false;
    });

    this.events.on("resume", () => {
      this.q_dom.visible = true;
    });
  }

  startTutorial() {
    this.tutorialChat.buttonText.removeInteractive();
    this.tutorialChat.removeInteractive();
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.4,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                  onComplete: () => {
                    this.tutorialChat.buttonText.setInteractive({
                      useHandCursor: true,
                    });
                    this.tutorialChat.setInteractive(
                      this.tutorialChatRect,
                      Phaser.Geom.Rectangle.Contains
                    );
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  continueChat() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.currentChatId++;
    if (this.chats[this.currentChatId]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatId]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
            onComplete: () => {
              this.tutorialChat.buttonText.setInteractive();
              this.tutorialChat.setInteractive(
                this.tutorialChatRect,
                Phaser.Geom.Rectangle.Contains
              );
            },
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.buttonText.setInteractive();
          this.tutorialChat.setInteractive(
            this.tutorialChatRect,
            Phaser.Geom.Rectangle.Contains
          );
          this.canAnswer = true;
          if (this.showClick) {
            this.showClick = false;
            this.tweens.add({
              onStart: () => {
                this.okHamochi.setDepth(10);
                this.okBtn.setDepth(10);
                this.okBtnTxt.setDepth(10);
                this.tutorialClick.setDepth(10);
              },
              targets: this.tutorialBackground,
              alpha: 0.4,
              duration: 200,
              ease: "Sine.easeOut",
              delay: 500,
              onComplete: () => {
                this.okBtn.setInteractive({ cursor: "pointer" });
                this.tweens.add({
                  targets: this.tutorialClick,
                  scaleX: 0.5,
                  scaleY: 0.5,
                  duration: 100,
                  ease: "Sine.easeInOut",
                  delay: 200,
                });
              },
            });
          }
        },
      });
    }
  }

  update() {
    //
  }
}
