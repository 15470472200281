import { Scene } from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";
import Phaser from "phaser";

export default class BeforeQuestionScene extends Scene {
  constructor() {
    super({ key: "BeforeQuestionScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.skyPosition = data.skyPosition ? data.skyPosition : 0;
  }

  playerAvatarObject = [];

  chats = [];
  currentChatId = 0;
  currentChatIndex = 0;

  create() {
    this.initilizeTutorial();
    this.registerSounds();
    this.registerAnims();

    //* setup the Background  *//
    this.imgSky = this.add
      .tileSprite(0, 0, 480, 854, "imgBackground")
      .setOrigin(0);
    this.imgBG = this.add.image(240, 400, "imgBG").setScale(0.5);
    this.imgSky.tilePositionX = this.skyPosition;

    this.imgFarShip = this.add
      .image(400, 498, "imgFarShip")
      .setOrigin(0)
      .setScale(0.5);
    this.imgWave1 = this.add
      .image(-50, 330, "imgWave1")
      .setOrigin(0)
      .setScale(0.4);
    this.imgWave2 = this.add
      .image(-40, 340, "imgWave2")
      .setOrigin(0)
      .setScale(0.4);
    this.imgRodWire = this.add
      .image(297, 501, "imgRodWire")
      .setOrigin(0)
      .setScale(0.3, 0.05)
      .setDepth(7);
    this.imgRod = this.add
      .image(150, 500, "imgRod")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);

    this.imgRodHook = this.add
      .image(285, 540, "imgRodHook")
      .setOrigin(0)
      .setScale(0.3)
      .setDepth(7);
    this.imgBoat = this.add
      .image(50, 600, "imgBoat")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(7);

    this.imgWave3 = this.add
      .image(-20, 360, "imgWave3")
      .setOrigin(0)
      .setScale(0.4)
      .setDepth(7);

    this.imgWave4 = this.add
      .image(-50, 340, "imgWave4")
      .setOrigin(0)
      .setScale(0.5)
      .setDepth(7);

    this.imgFishingBtn = this.add
      .sprite(150, 380, "sptIdea")
      .setOrigin(0.5)
      .setDepth(7)
      .setScale(1.2)
      .setAlpha(0);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreBoard = this.add.image(100, 40, "imgScorePanel").setScale(0.4);
    this.roundBoard = this.add.image(73, 85, "imgScorePanel").setScale(0.25);

    this.scoreText = this.add
      .text(
        40,
        40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5);

    this.roundText = this.add
      .text(35, 87, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);

    this.dark = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(120, 560, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerNameBoard = this.add.rectangle(120, 480, 180, 40, 0xffffff, 0.5);

    this.playerName = this.add
      .text(
        120,
        480,
        this.name.length > 20 ? this.name.substring(0, 12) : this.name,
        {
          color: "#000",
          fontSize: "15px",
          fontFamily: "Sassoon, sans-serif",
          align: "center",
        }
      )
      .setOrigin(0.5);

    this.itemTween1 = this.tweens.add({
      targets: [
        this.imgWave3,
        this.imgBoat,
        this.imgRod,
        // this.imgRodHook,
        this.imgRodWire,
        // this.playerName,
        // this.playerNameBoard,
      ],
      y: "-=10",
      duration: 4000,
      repeat: -1,
      yoyo: true,
    });

    this.roundPanel = [
      this.add.image(240, 350, "imgRoundPanel").setDepth(9).setScale(0),

      this.add
        .text(240, 350, i18n.t("miniGames.general.round") + " " + this.round, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 30,
          color: "#000",
        })
        .setOrigin(0.5)
        .setDepth(9)
        .setScale(0),
    ];

    // all starts here
    if (this.round == 1) {
      this.currentChatId = 1;
      this.currentChatIndex = 0;
      this.chats = this.chats_1;
      this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
      this.startTutorial();
    } else {
      this.startGame();
    }

    this.itemTween2 = this.tweens.add({
      targets: [this.imgWave1, this.imgFarShip],
      y: "-=5",
      duration: 3000,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween3 = this.tweens.add({
      targets: [this.imgWave2],
      y: "-=10",
      duration: 3500,
      repeat: -1,
      yoyo: true,
    });

    this.itemTween4 = this.tweens.add({
      targets: [this.imgWave4],
      y: "-=20",
      duration: 4500,
      repeat: -1,
      yoyo: true,
    });

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 4000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
  }

  update() {
    this.imgSky.tilePositionX += 0.2;
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }
  }

  fishing() {
    this.imgFishingBtn.anims.stop("animIdea");
    this.imgFishingBtn.removeInteractive();
    this.tweens.add({
      targets: this.imgFishingBtn,
      alpha: 0,
      duration: 100,
      ease: "Sine.easeInOut",
    });

    this.sfxStart.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxStart.play();
    this.tweens.add({
      targets: [this.imgRodWire],
      scaleY: 0.3,
      duration: 1000,
      onStart: () => {
        if (this.currentChatId == -2) {
          this.tutorialActionBackground.setAlpha(0);
          this.tutorialClick.setScale(0);
        }
        this.tweens.add({
          targets: [this.imgRodHook],
          y: "+= 230",
          duration: 1000,
        });
      },
      onComplete: () => {
        if (this.currentChatId == -2) {
          this.currentChatId = 3;
          this.currentChatIndex = 0;
          this.chats = this.chats_3;
          this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
          this.startTutorial();
        } else {
          this.goQuestion();
        }
      },
    });
  }

  startGame() {
    this.tweens.add({
      targets: this.roundPanel,
      scaleX: 1,
      scaleY: 1,
      duration: 200,
      delay: 500,
      onStart: () => {
        this.sfxRound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxRound.play();
        this.dark.setAlpha(0.5);
        this.dark.setDepth(8);
      },
      onComplete: () => {
        this.tweens.add({
          targets: this.roundPanel,
          scaleX: 0,
          scaleY: 0,
          duration: 200,
          delay: 1500,
          onComplete: () => {
            this.dark.setAlpha(0);
            this.tweens.add({
              targets: this.imgFishingBtn,
              alpha: 1,
              duration: 200,
              delay: 500,
              onComplete: () => {
                if (this.currentChatId == -1) {
                  this.currentChatId = 2;
                  this.currentChatIndex = 0;
                  this.chats = this.chats_2;
                  this.tutorialChat.text.setText(
                    this.chats[this.currentChatIndex]
                  );
                  this.startTutorial();
                } else {
                  this.imgFishingBtn
                    .setInteractive({ useHandCursor: true })
                    .on("pointerdown", this.fishing, this);
                  this.imgFishingBtn.play("animIdea");
                }
              },
            });
          },
        });
      },
    });
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxRound = this.sound.add("sfxRound");
    this.sfxStart = this.sound.add("sfxStart");
  }

  registerAnims() {
    this.anims.create({
      key: "animIdea",
      frames: this.anims.generateFrameNumbers("sptIdea", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });
  }

  initilizeTutorial() {
    this.chats_1 = [
      i18n.t("miniGames.fishing.tutorial.dialog_1"),
      i18n.t("miniGames.fishing.tutorial.dialog_2"),
    ];
    this.chats_2 = [i18n.t("miniGames.fishing.tutorial.dialog_3")];
    this.chats_3 = [
      i18n.t("miniGames.fishing.tutorial.dialog_4_1"),
      i18n.t("miniGames.fishing.tutorial.dialog_4_2"),
    ];

    this.tutorialBackground = this.add.rectangle(240, 427, 480, 854, 0x000000);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      if (!this.onShake) {
        this.onShake = true;
        this.tweens.add({
          targets: this.tutorialChat,
          x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.onShake = false;
          },
        });
        this.tweens.add({
          targets: this.tutorialChat.chat,
          x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.text,
          x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.button,
          x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.buttonText,
          x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
      }
    });
    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 680, 460, 154, 20);

    this.anims.create({
      key: "animClick",
      frames: this.anims.generateFrameNumbers("sptClick", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add
      .sprite(0, 0, "sptClick")
      .setScale(0)
      .setDepth(10)
      .play("animClick");

    this.tutorialActionBackground = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0)
      .setDepth(8);

    this.tutorialArrow = this.add
      .image(0, 0, "imgArrow")
      .setScale(0)
      .setDepth(10);

    this.tutorialChatRect = new Phaser.Geom.Rectangle(10, 680, 460, 154);

    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add
      .image(90, 755, "imgHamochiTalk")
      .setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 740, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(334, 802, 124, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      340,
      805,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialBackground.setDepth(9);
    this.tutorialChat.setDepth(9);
    this.tutorialChat.chat.setDepth(9);
    this.tutorialChat.text.setDepth(9);
    this.tutorialChat.button.setDepth(9);
    this.tutorialChat.buttonText.setDepth(9);

    this.tutorialBackground.setAlpha(0);
    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);
  }

  startTutorial() {
    this.tutorialChat.buttonText.removeInteractive();
    this.tutorialChat.removeInteractive();
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.7,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                  onComplete: () => {
                    this.tutorialChat.buttonText.setInteractive({
                      useHandCursor: true,
                    });
                    this.tutorialChat.setInteractive(
                      this.tutorialChatRect,
                      Phaser.Geom.Rectangle.Contains
                    );
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  continueChat() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.currentChatIndex++;
    this.tutorialChat.buttonText.removeInteractive();
    this.tutorialChat.removeInteractive();
    if (this.chats[this.currentChatIndex]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatIndex]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
            onComplete: () => {
              this.tutorialChat.buttonText.setInteractive({
                useHandCursor: true,
              });
              this.tutorialChat.setInteractive(
                this.tutorialChatRect,
                Phaser.Geom.Rectangle.Contains
              );
            },
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.buttonText.setInteractive({ useHandCursor: true });
          this.tutorialChat.setInteractive(
            this.tutorialChatRect,
            Phaser.Geom.Rectangle.Contains
          );
        },
      });

      if (this.currentChatId == 1) {
        this.currentChatId = -1;
        this.startGame();
      }
      if (this.currentChatId == 2) {
        this.currentChatId = -2;
        this.imgFishingBtn
          .setInteractive({ useHandCursor: true })
          .on("pointerdown", this.fishing, this);
        this.imgFishingBtn.play("animIdea");
        // this.btnTween = this.tweens.add({
        //   targets: [
        //     this.imgFishingBtn,
        //     this.imgFishingBtnTxt
        //   ],
        //   scaleX: 0.95,
        //   scaleY: 0.95,
        //   duration: 500,
        //   repeat: -1,
        //   yoyo: true,
        // });
        this.tutorialActionBackground.setAlpha(0.6);
        this.imgFishingBtn.setDepth(9);
        // this.imgFishingBtnTxt.setDepth(9);

        this.tutorialClick.x = this.imgFishingBtn.x + 50;
        this.tutorialClick.y = this.imgFishingBtn.y + 20;
        this.time.delayedCall(500, () => {
          this.tutorialClick.setScale(0.4);
        });
      }
      if (this.currentChatId == 3) {
        this.goQuestion();
      }
    }
  }

  goQuestion() {
    this.playerAvatarObject = [];

    this.registry.destroy();
    this.events.off();
    this.scene.stop("BeforeQuestionScene");

    this.scene.start("QuestionScene", {
      avatar: this.avatar,
      name: this.name,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      noOfRounds: this.noOfRounds,
      skyPosition: this.imgSky.tilePositionX,
    });
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "BeforeQuestionScene",
    });
  }
}
